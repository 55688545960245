<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-solution-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 position-relative">
        <div class="w-md-80 w-xl-60 text-center mx-md-auto">
          <div class="mb-7">
            <span class="d-block small font-weight-bold text-cap mb-2">our solutions</span>
            <h1>Empowering Your AI and API Gateway Success with beNovelty</h1>
            <p>Our AI and API Gateway, powered by <a href="https://www.fabrixapi.com/" target="_blank">FabriXAPI</a>, is currently in the Beta phase. Join our AI Gateway waitlist to be the first to experience this exciting new feature!</p>
          </div>
          <div class="form-subscribe mb-7 ml-4">
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://fabrixapi.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=221f0de8c7&amp;f_id=003396e6f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="blank">
                <div class="mc-field-group">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
                </div>
                <div class="mb-4">
                  <p class="small">By submitting your information, you agree to receive future communications from FabriXAPI by beNovelty.</p>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="2979393"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_221f0de8c7" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Join Waitlist" name="subscribe" class="btn btn-wide btn-primary border-0 mx-auto mb-0"></div>
              </form>
            </div>
            <!--End mc_embed_signup-->
            <!-- <form ref="subscriptionForm" /> -->
          </div>
          <img class="img-fluid" src="@/assets/svg/illustrations/ai-gateway.svg">
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Overview Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">Why AI Gateway?</span>
        <h2>Supercharge AI integration in your applications</h2>
      </div>
      <div class="row">
        <div class="col-md-4 mb-5 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-47.svg">
            </figure>
            <h3>Streamline AI traffic usage, security, and monitoring</h3>
            <p>Effortlessly utilize, safeguard, and monitor the leading Large Language Models (LLMs) such as OpenAI, Azure AI, Cohere, Anthrophic, LLamMA, and Mistral.</p>
          </div>
        </div>

        <div class="col-md-4 mb-5 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-44.svg">
            </figure>
            <h3>Harness advanced AI contexts and prompts</h3>
            <p>Integrate sophisticated AI Prompt Engineering techniques into your AI requests to ensure compliance and governance in AI implementation.</p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-3.svg">
            </figure>
            <h3>Seamlessly integrate AI with no-code solutions</h3>
            <p>Enhance your existing API traffic with AI capabilities using our user-friendly, code-free AI plugins, eliminating the need for manual coding in your applications.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Overview Section ========== -->

    <!-- ========== Blog Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5">
          <h2 class="mb-3">Want to Know More About AI / API Gateway?</h2>
          <p>Let's dive into AI / API Gateway with ur curated articles</p>
        </div>

        <div class="row w-lg-80 mx-auto">
          <div class="col-md-6 mb-4 mb-md-0">
            <a class="card h-100" href="https://blog.openapihub.com/en-us/role-of-ai-gateways-in-building-ai-apps-at-scale/" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/ai-gateways.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>From APIs to AI: The Role of AI Gateways in Building AI Apps at Scale</h4>
                  <p class="font-size-1">In the world of software development, API Gateways serve as crucial connectors between clients and…</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
          <div class="col-md-6 mb-0">
            <a class="card h-100" href="https://blog.openapihub.com/en-us/navigating-api-gateway-a-guide-for-app-developers/" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/navigating-api-gateway.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Navigating API Gateway: A Guide for App Developers</h4>
                  <p class="font-size-1">As emerging developers in the tech industry, understanding the infrastructure that powers modern applications is crucial...</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Blog Section ========== -->

    <!-- ==========  CTA Section ==========  -->
    <!-- <div class="container space-2 space-lg-3">
      <div class="w-md-80 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">you're in good company</span>
        <h2>Powering Innovative Enterprises</h2>
      </div>
      <PartnerCarousel :items="api_partner_list" />
      <div class="mt-4">
        <ContactUsBar :detail="{ title: 'Interested in Partnering with Us?' }" />
      </div>
    </div> -->
    <div class="container space-2 text-center">
      <div class="mb-6">
        <h2>Ready to Embark on the APIs Meet AI Journey with Us?</h2>
        <p>Contact us and explore more about AI Gateway!</p>
      </div>
      <router-link :to="{ path: '/contact' }" class="btn btn-primary transition-3d-hover">Let's Talk</router-link>
    </div>
    <!-- ========== End CTA Section ==========  -->

    <!-- ========== More Solutions Section ==========-->
    <div
      class="space-2 space-lg-3 bg-blue-light"
      :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-9-1-01.svg') + ')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}"
    >
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">More by beNovelty</span>
        <h2>Want to Know More About API Economy?</h2>
      </div>
      <div class="position-relative">
        <div class="container">
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-3"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;infinite&quot;: true,
                &quot;dots&quot;: true,
                &quot;dotsClass&quot;: &quot;slick-pagination d-lg-none&quot;,
                &quot;autoplay&quot;: true,
                &quot;autoplaySpeed&quot;: 5000,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-4.svg">
                  <h4>API Consultancy</h4>
                  <p>Read more to find out what makes beNovelty different in API consultancy.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-consultancy-button" href="/solutions/api-consultancy"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-consultancy-button" :to="{ path: '/solutions/api-consultancy' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <!-- <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-28.svg">
                  <h4>API Product</h4>
                  <p>Find out the 3 simple steps for building API-as-a-Products.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <a id="ga-solutions-more-by-benovelty-api-product-button" href="/solutions/api-product">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></a>
                </div>
              </div>
            </div> -->
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-3.svg">
                  <h4>API Management</h4>
                  <p>Learn how to manage, secure, mediate, analyze complex enterprise API programme with API Management.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-management-button" href="/solutions/api-management"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-management-button" :to="{ path: '/solutions/api-management' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-69.svg">
                  <h4>API Community</h4>
                  <p>Discover more on our latest API community events.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-community-button" href="/solutions/api-community"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-community-button" :to="{ path: '/solutions/api-community' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Solutions Section ========== -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.slick-carousel.js'
import '../../../assets/vendor/slick-carousel/slick/slick.js'
import { mapGetters } from 'vuex'
export default {
  name: 'AiGateway',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
  },
  created() {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'AI Gateway | Solutions | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/solutions/ai-gateway' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'AI Gateway | Solutions | beNovelty' },
        { property: 'og:description', content: 'Experience the untapped potential of AI with beNovelty\'s AI Gateway. Effortlessly integrate and streamline AI models, guaranteeing top-notch security and optimal performance.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/solutions/ai-gateway' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
